<template>
  <div class="eyeDiseaseHome">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "eyeDiseaseHome"
}
</script>

<style scoped lang="scss">
.eyeDiseaseHome {
  width: 100vw;
  height: 87vh;
  overflow: hidden;
  overflow-y: auto;
}
</style>
